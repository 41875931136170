<template>
    <div>
      <video ref="videoElement" autoplay></video>
      <button @click="startStream" v-if="!streaming">Start Streaming</button>
      <button @click="stopStream" v-if="streaming">Stop Streaming</button>
    </div>
  </template>
  
  <script>
  import io from 'socket.io-client';
  
  export default {
    data() {
      return {
        socket: null,
        streaming: false,
        mediaStream: null,
        mediaRecorder: null
      };
    },
    mounted() {
      this.socket = io('http://localhost:3000');
  
      this.socket.on('connect', () => {
        console.log('Connected to server');
      });
  
      this.socket.on('disconnect', () => {
        console.log('Disconnected from server');
      });
    },
    methods: {
      startStream() {
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
          .then((stream) => {
            this.$refs.videoElement.srcObject = stream;
            this.mediaStream = stream;
            this.streaming = true;
            this.socket.emit('startStream');
  
            this.mediaRecorder = new MediaRecorder(stream, { mimeType: 'video/webm; codecs=vp9, opus' });
            this.mediaRecorder.ondataavailable = (event) => {
              if (event.data.size > 0) {
                this.socket.emit('streamData', event.data);
              }
            };
            this.mediaRecorder.start(1000);  // Send data every second
          })
          .catch((error) => {
            console.error('Error accessing media devices:', error);
          });
      },
      stopStream() {
        if (this.mediaStream) {
          this.mediaStream.getTracks().forEach(track => track.stop());
          this.streaming = false;
          this.mediaRecorder.stop();
          this.socket.emit('stopStream');
        }
      }
    }
  };
  </script>
  
  <style>
  /* Add your styles here */
  </style>
  