<template>
  <div>
    <h2>Live Streams</h2>
    <div v-for="(stream, index) in streams" :key="index">
      <video :ref="setVideoRef(index)" controls autoplay></video>
    </div>

    <h2>Recorded Videos</h2>
    <div class="recorded-videos">
      <ul class="video-list">
        <li v-for="(video, index) in recordedVideos" :key="index">
          <a href="#" @click.prevent="playVideo(video)"><span>{{ index+1 }}. </span>{{ video }}</a>
        </li>
      </ul>
      <div class="video-player">
        <video ref="recordedVideoPlayer" controls></video>
      </div>
    </div>
  </div>
</template>

<script>
import io from 'socket.io-client';

export default {
  data() {
    return {
      socket: null,
      streams: [],
      mediaSources: {},
      sourceBuffers: {},
      videoRefs: {},
      recordedVideos: [],
    };
  },
  mounted() {
    this.socket = io('http://localhost:3000');

    this.socket.on('connect', () => {
      console.log('Connected to server');
    });

    this.socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    this.socket.on('newStream', (id) => {
      console.log('New stream available:', id);
      const mediaSource = new MediaSource();
      this.mediaSources[id] = mediaSource;
      this.streams.push({ id, mediaSource });

      this.$nextTick(() => {
        const videoElement = this.videoRefs[this.streams.length - 1];
        if (videoElement) {
          videoElement.src = URL.createObjectURL(mediaSource);
          mediaSource.addEventListener('sourceopen', () => {
            const sourceBuffer = mediaSource.addSourceBuffer('video/webm; codecs=vp9,opus');
            this.sourceBuffers[id] = sourceBuffer;

            sourceBuffer.addEventListener('error', (e) => {
              console.error('SourceBuffer error:', e);
            });

            sourceBuffer.addEventListener('updateend', () => {
              console.log('SourceBuffer update end');
              this.socket.emit('bufferUpdated', id);
            });
          });
        }
      });
    });

    this.socket.on('streamData', ({ id, data }) => {
      const sourceBuffer = this.sourceBuffers[id];
      if (sourceBuffer) {
        const uint8ArrayData = new Uint8Array(data);
        if (!sourceBuffer.updating && this.mediaSources[id].readyState === 'open') {
          try {
            sourceBuffer.appendBuffer(uint8ArrayData);
          } catch (error) {
            console.error('Error appending buffer:', error);
          }
        } else {
          sourceBuffer.addEventListener('updateend', () => {
            try {
              sourceBuffer.appendBuffer(uint8ArrayData);
            } catch (error) {
              console.error('Error appending buffer on updateend:', error);
            }
          }, { once: true });
        }
      }
    });

    this.socket.on('streamEnded', (id) => {
      console.log('Stream ended:', id);
      const index = this.streams.findIndex(s => s.id === id);
      if (index !== -1) {
        this.streams.splice(index, 1);
        delete this.videoRefs[index];
        delete this.mediaSources[id];
        delete this.sourceBuffers[id];
      }
    });

    // Fetch recorded videos
    this.fetchRecordedVideos();

    // Listen for new videos
    this.socket.on('newVideo', (video) => {
      this.recordedVideos.unshift(video); 
    });
  },
  methods: {
    setVideoRef(index) {
      return (el) => {
        this.videoRefs[index] = el;
      };
    },
    fetchRecordedVideos() {
      fetch('http://localhost:3000/videos')
        .then(response => response.json())
        .then(videos => {
          this.recordedVideos = videos.reverse();
        })
        .catch(error => {
          console.error('Error fetching recorded videos:', error);
        });
    },
    playVideo(video) {
      const videoElement = this.$refs.recordedVideoPlayer;
      videoElement.src = `http://localhost:3000/uploads/${video}`;
      videoElement.play();
    }
  }
};
</script>

<style>
.recorded-videos {
  display: flex;
  align-items: flex-start;
}

.video-player {
  flex: 1;
}

.video-list {
  flex: 1;
  list-style-type: none;
  padding-left: 0;
}

.video-list li {
  margin-bottom: 10px;
}
</style>
