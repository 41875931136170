<template>
  <div id="app">
    <StreamingComponent />
    <ViewingComponent />
  </div>
</template>

<script>
import StreamingComponent from './components/StreamingComponent.vue';
import ViewingComponent from './components/ViewingComponent.vue';

export default {
  components: {
    StreamingComponent,
    ViewingComponent
  }
};
</script>

<style>
/* Add your styles here */
</style>
